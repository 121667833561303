<template>
  <div>
    <vue-headful :title="pageTitle" />

    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>

    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id=".3">
          <!-- The buttons for adding / removing Menu Items. -->
          <div id="buttongroup" style="margin-top: 0rem; margin-bottom: 1rem; display: flex; justify-content: flex-end;">
              <div class="level">
                <div class="level-left">
                    <div class="field is-grouped">
                      <div class="control">
                          <a :class="['button is-accent', !$hasPermissions(clientSession, ['CARRIERS'], 2) ? 'disabled' : '' ]">
                            <span class="icon">
                              <i class="fal fa-plus-square"></i>
                            </span>
                            <span>
                              <router-link :to="{ name: !$hasPermissions(clientSession, ['CARRIERS'], 2) ? '' : 'CarrierManagementNewScreen', params: { carrier: {} }}" class="has-text-white">
                                Add Carrier
                              </router-link>
                            </span>
                          </a>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <table ref="table" class="table is-striped is-bordered" style="width: 100%;">
            <thead>
              <tr>
                <th class="cms" style="width: 75%;">Name</th>
                <th class="cms" style="width: 10%; text-align: left;">Short Name</th>
                <th class="cms" style="width: 5%; text-align: center;">Actions</th>
              </tr>
            </thead>
            <tbody v-if="carriers.length > 1">
              <tr v-for="carrier in carriers.filter((_carrier) => _carrier.code !== 'BCC')" :key="carrier.id">
                <td>
                  <router-link :to="{ name: 'CarrierManagementEditScreen', params: { carrier: carrier, code: carrier.code }, query: { code: carrier.code } }">
                    {{carrier.name}}
                  </router-link>
                </td>
                <td>{{carrier.code}}</td>
                <td>
                  <div class="actions">
                    <button @click="edit(carrier)" v-tooltip.left-start="{ content: 'Edit Carrier', container: false, classes: ['tooltip'] }">
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <button :disabled="!$hasPermissions(clientSession, ['CARRIERS'], 2)" :class="['trash', !$hasPermissions(clientSession, ['CARRIERS'], 2) ? 'disabled' : '']" @click="remove(carrier)" v-tooltip.left-start="{ content: 'Delete Carrier', container: false, classes: ['tooltip'] }">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="carriers.length === 1">
              <tr>
                <td colspan="3">
                  No Carriers In System
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Delete modal: // TODO [BD|05-2021] Wire this up to prompt the user to delete the items -->
          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger" v-if="isDeletable">Confirm Delete</div>
                  <div class="modal-card-title has-bg-danger" v-else>Unable To Delete Carrier</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                  <div class="content" v-if="isDeletable">
                  Type "<b>delete</b>" to confirm that you want to delete "{{ selectedCarrier.name }}"
                  </div>
                  <div class="content" v-else>
                      <p>
                      <b>{{ selectedCarrier.name }}</b> cannot be deleted because it is currently in use by the following institutions:
                      </p>
                      <ul>
                        <li v-for="institution in selectedCarrier.institutions" :key="institution">{{ institution.name }}</li>
                      </ul>
                  </div>
                  <div class="field" v-if="isDeletable">
                  <div class="control" >
                      <input
                      type="text"
                      class="input"
                      placeholder="type `delete` and then click confirm"
                      v-focus
                      v-model.trim="confirmationText"
                      @keydown.enter.prevent="deleteIfConfirmed"
                      />
                  </div>
                  </div>
              </section>
              <footer class="modal-card-foot">
                  <a
                  class="button is-danger"
                  @click="deleteIfConfirmed"
                  :disabled="confirmationText !== 'delete'"
                  v-if="isDeletable"
                  >
                  <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

let pageName = 'Carrier Management'

export default {
  data () {
    return {
      confirmationText: '',
      isLoading: false,
      showDeleteModal: false,
      pageheading: pageName,
      currentItem: null,
      carriers: [],
      isDeletable: false
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  created () {
    this.getCarriers()
  },
  methods: {
    async getCarriers () {
      let response = await this.axios.get('carriers?perPage=1000&page=1&sortBy=name&sortDirection=asc')
      if (response.status === 200) {
        this.carriers = response.data.records
      }
    },
    async checkIfCarrierIsDeletable (carrier) {
      let response = await this.axios.get('institutions/by-carrier/' + carrier.code + '?perPage=1000&page=1&sortBy=name&sortDirection=asc&carrierCode=' + carrier.code)
      if (response.status === 200) {
        this.selectedCarrier = carrier
        this.selectedCarrier.institutions = response.data.records
        this.isDeletable = response.data.records.length === 0
        this.showDeleteModal = true
      }
    },
    async deleteIfConfirmed () {
      if (this.confirmationText === 'delete') {
        try {
          let response = await this.axios.delete('/carriers/' + this.selectedCarrier.code)

          if (response.status === 204) {
            this.showDeleteModal = false
            this.selectedCarrier = null
            this.confirmationText = ''
            this.getCarriers()
          }
        } catch (e) {
          // show error toast
          this.errorToast('Unable to delete carrier due to active institutions using this carrier.')
          this.showDeleteModal = false
          this.selectedCarrier = null
          this.getCarriers()
          this.confirmationText = ''
        }
      }
    },
    edit (carrier) {
      this.$router.push({ name: 'CarrierManagementEditScreen', params: { carrier: carrier }, query: { code: carrier.code } })
    },
    remove (carrier) {
      this.checkIfCarrierIsDeletable(carrier)
    }
  }
}
</script>

<style>
.actions {
  display: flex;
  justify-content: center;
  border-top: 0!important;
  border-left: 0!important;
}

tr:hover > td .actions  button {
  visibility: visible;
}

th.cms {
  background-color: #f5f5f5;
  font-size: 14px!important;
  font-weight: 600!important;
  color: #595959!important;

  font-family: "Poppins", Helvetica, sans-serif;;
  font-size: 14px;
}

.actions button {
  background-color: transparent!important;
  border: 0px;
  margin: 0px 5px;
  cursor: pointer;

  color: #97c666;

  padding: 0px;
}

/* Page Layout */
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.placeholder {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: #7fb942;
}
</style>
